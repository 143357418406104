const SkillBg=()=>{
    return(
<div  >



 <iframe
  className=" aspect-video  fixed w-screen"
  //width="560"
  //height="315"
   src="https://www.youtube.com/embed/BadB1z-V_qU?si=lXa8WzeS-rE5IH8i?&autoplay=1&mute=1&loop=1&playlist=BadB1z-V_qU" 
 //title="YouTube video player"accelerometer;  
 frameborder="0" 
 allow="autoplay; "
  allowfullscreen></iframe>
</div>

    )
}
export default SkillBg