const ProjectBg=()=>{
//const array=["WkM07RJs9-E","WkM07RJs9-E","WkM07RJs9-E"]
//const randomPick=Math.floor(Math.random()*array.length)
//console.log(randomPick)

    return(
                
<>


<div >
<img src="lib.jpg" className="w-screen aspect-video fixed h-screen"></img>
</div>


 


</>
    )
}
export default ProjectBg